import React from "react";
import { renderEmail } from 'react-html-email'
import EmailPedidoUsuario from './../views/Emails/EmailPedidoUsuario.js'
import config from './../config/config.js'

const rutaApi = config.rutaApisCliente + '/emails'

const enviaEmail = (emailDestino, pedido, tipoEmail, subject, credentials) => {
  let subject1 = ""
  let messageHtml1 = ""
  let subject2 = ""
  let messageHtml2 = ""
  //      
  if (tipoEmail === "1") {
    //
    subject1 = subject
    messageHtml1 = renderEmail(<EmailPedidoUsuario pedido={pedido}></EmailPedidoUsuario>)
    subject2 = subject
    messageHtml2 = renderEmail(<EmailPedidoUsuario pedido={pedido}></EmailPedidoUsuario>)
  }
  return fetch(rutaApi + '/enviaEmail/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Allow-Control-Allow-Origin": 'http://localhost:3000',
      'Authorization': 'Bearer ' + credentials.t
    },
    credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject1: subject1,
      mensaje1: messageHtml1,
      subject2: subject2,
      mensaje2: messageHtml2
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



const enviaEmailPassword = (emailDestino, idUsuario) => {
  let subject = "Cambio de Password"
  let messageHtml = 'Código para cambio de contraseña : ' + idUsuario

  return fetch(rutaApi + '/enviaEmailPassword/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject: subject,
      mensaje: messageHtml,
      id: idUsuario
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



const enviaEmailPeticion = (emailDestino) => {
  let subject = "Solicitud de alta"
  let messageHtml = 'Ha solicitado el alta en un mercado.'

  return fetch(rutaApi + '/enviaEmailPeticion/', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    //credentials: 'include',
    body: JSON.stringify({
      email: emailDestino,
      subject: subject,
      mensaje: messageHtml
    })
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => console.log('Error = ', err))
}



export {
  enviaEmail,
  enviaEmailPassword,
  enviaEmailPeticion
}